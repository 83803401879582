import { render, staticRenderFns } from "./proDetail.vue?vue&type=template&id=254936c1&scoped=true&"
import script from "./proDetail.vue?vue&type=script&lang=js&"
export * from "./proDetail.vue?vue&type=script&lang=js&"
import style1 from "./proDetail.vue?vue&type=style&index=1&id=254936c1&scoped=true&lang=css&"
import style2 from "./proDetail.vue?vue&type=style&index=2&id=254936c1&scoped=scoped&lang=css&"
import style3 from "./proDetail.vue?vue&type=style&index=3&id=254936c1&scoped=true&lang=css&"
import style4 from "./proDetail.vue?vue&type=style&index=4&id=254936c1&scoped=true&lang=css&"
import style5 from "./proDetail.vue?vue&type=style&index=5&id=254936c1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254936c1",
  null
  
)

export default component.exports